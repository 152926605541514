import { createSlice } from '@reduxjs/toolkit';
const token = localStorage.getItem('token');
const initialState = {
  // rootApiUrl: 'http://127.0.0.1:8000/',
  rootApiUrl: 'https://api.biopixelsolutions.com/',
  isLogedIn: token ? true : false,
};

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setIsLogedIn(state, action) {
      state.isLogedIn = action.payload;
    },
  },
});

export const globalAct = globalSlice.actions;

export default globalSlice.reducer;
