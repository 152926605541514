import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { globalAct } from '../store/global';
import bgImg from '../images/bgImg.jpg';
import logoImg from '../images/logo.jpg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppLoader from '../components/ui/AppLoader';

function LoginPage() {
  const [searchParams] = useSearchParams();

  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(false);
  const { rootApiUrl, isLogedIn } = useSelector((state) => state.global);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLogedIn) {
      const back = searchParams.get('back');
      if (back) {
        navigate(back);
      } else {
        navigate('/');
      }
    }
  }, [isLogedIn]);

  const login = async () => {
    setLoading(true);
    const res = await fetch(rootApiUrl + 'login/', {
      method: 'POST',
      body: JSON.stringify({
        username: username,
        password: password,
      }),
      headers: { 'Content-Type': 'application/json' },
    });
    if (res.ok) {
      let data = await res.json();
      localStorage.setItem('token', data.token);
      localStorage.setItem('userId', data.response.id);
      localStorage.setItem('Is_Superuser', data.response.is_superuser);
      dispatch(globalAct.setIsLogedIn(true));
      setLoading(false);
    } else {
      setLoading(false);
      let data = await res.json();
      toast.error(data.message);
    }
  };

  const handleInputChange = (e) => {
    const target = e.target;
    if (target.title === 'username') {
      setUsername(target.value);
    }
    if (target.title === 'password') {
      setPassword(target.value);
    }
  };

  return (
    <>
      <AppLoader show={loading} />
      <ToastContainer
        position='top-center'
        closeOnClick
        pauseOnHover
        autoClose={2500}
      />
      <div className='grid h-screen grid-cols-12 bg-ext-light text-ext-green-light'>
        <div className='relative col-span-8 overflow-auto'>
          <img src={bgImg} alt='' className='object-cover w-full h-full' />
          <div className='absolute z-10 w-full p-5 bg-white/50 top-1/3'>
            <img src={logoImg} alt='' className='mx-auto' />
          </div>
        </div>
        <div className='flex items-center justify-center h-full col-span-4'>
          <div className='w-full max-w-xs'>
            <h2 className='mb-8 text-5xl font-light text-ext-dark'>Login</h2>
            <input
              type='text'
              className='mb-5 form-control'
              title='username'
              placeholder='Username'
              onChange={handleInputChange}
            />
            <input
              type='password'
              className='mb-5 form-control'
              title='password'
              placeholder='Password'
              onChange={handleInputChange}
            />
            <button className='w-full btn-primary' onClick={login}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginPage;
