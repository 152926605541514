import React, { useEffect, useState, useMemo } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import AppHeader from '../components/AppHeader';
import MyMap from '../components/Mapping/components/Map/MyMap';
import {
  MyLayers,
  VectorLayer,
  TileLayer,
} from '../components/Mapping/components/Layers';
import { fromLonLat, get } from 'ol/proj';
import { osm, vector, xyz } from '../components/Mapping/components/Source';
import GeoJSON from 'ol/format/GeoJSON';
import AppLoader from '../components/ui/AppLoader';
import { useSelector, useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import Popup from '../components/Mapping/components/Popup/Popup';
import imgNorth from '../images/north.png';
import FilterComponent from '../components/FilterComponent';
import authHeader from '../services/auth-header';
import { globalAct } from '../store/global';

function Dashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { rootApiUrl, isLogedIn } = useSelector((state) => state.global);

  const [loading, setLoading] = useState(true);
  const [farmarData, setFarmarData] = useState([]);
  const [totalArea, setTotalArea] = useState();
  const [geoj, setGeoj] = useState(null);
  let center = [78.9629, 20.5937];

  const columns = [
    {
      name: 'Crop ID',
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: 'Crop',
      selector: (row) => row.properties.crop,
      sortable: true,
    },
    {
      name: 'Sowing Date',
      selector: (row) => row.properties.sowingdate,
      sortable: true,
    },
    {
      name: 'Area',
      selector: (row) => (row.properties.area / 4046).toFixed(2),
      sortable: true,
      right: true,
    },
    {
      name: '',
      selector: (row) => (
        <NavLink to={'/farms/' + row.id}>
          <FontAwesomeIcon
            className='text-xl text-ext-green'
            icon={faCaretRight}
          />
        </NavLink>
      ),
      width: '80px',
      right: true,
    },
  ];

  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  useEffect(() => {
    if (!isLogedIn) {
      navigate('/login');
    }
  }, [isLogedIn]);

  // const filteredItems = data.filter(
  //   item => item.name && item.name.includes(filterText)
  // );
  const filteredItems = farmarData.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  useEffect(() => {
    async function getFarmData() {
      setLoading(true);

      var requestOptions = {
        method: 'GET',
        headers: authHeader(),
        redirect: 'follow',
      };
      const res = await fetch(rootApiUrl + 'onefarm/', requestOptions);

      if (res.status === 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem('Is_Superuser');
        dispatch(globalAct.setIsLogedIn(false));
        return;
      } else {
        const userId = localStorage.getItem('userId');
        const Is_Superuser = localStorage.getItem('Is_Superuser');
        const data = await res.json();
        const features =
          Is_Superuser == 'true'
            ? data.features
            : data.features.filter((val) => {
                return val.properties.user == userId;
              });
        const farmData = { type: 'FeatureCollection', features: features };
        setFarmarData(farmData.features);
        setGeoj(farmData);
        let totalArea = 0;
        if (farmData.features.length) {
          for (let i = 0; i < farmData.features.length; i++) {
            totalArea += farmData.features[i].properties.area;
          }
        }
        setTotalArea(totalArea);
      }
      setLoading(false);
    }
    getFarmData();
  }, []);

  return (
    <div className='flex flex-col-reverse h-screen lg:flex-row'>
      <div className='relative h-screen basis-7/12 bg-ext-grey text-ext-green-light'>
        <AppHeader />
        <AppLoader show={loading} />
        <div className='grid grid-cols-12 px-5 py-3 bg-ext-beige lg:py-6'>
          <div className='col-span-6 text-center'>
            <div className='opacity-50 text-ext-dark'>Number of Farms</div>
            <div className='text-2xl font-extralight text-ext-dark'>
              {farmarData.length}
            </div>
          </div>
          <div className='col-span-6 text-center'>
            <div className='opacity-50 text-ext-dark'>Total Area</div>
            <div className='text-2xl font-extralight text-ext-dark'>
              {(totalArea / 4046).toFixed(2)} Acre
            </div>
          </div>
        </div>

        <div className='absolute bottom-0 left-0 right-0 px-5 py-6 overflow-auto top-40 lg:top-48'>
          <h3 className='pb-2 text-xl border-b font-extralight border-ext-darker text-ext-dark'>
            List of Farms
          </h3>

          <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            theme='light'
            subHeader
            subHeaderComponent={subHeaderComponent}
          />
        </div>
      </div>
      <div className='relative basis-5/12'>
        <MyMap center={center} zoom={12} trackLocation={false}>
          <img
            src={imgNorth}
            alt='north'
            className='absolute z-10 object-contain p-1 rounded-md w-9 h-9 bg-ext-green-light bg-opacity-60 right-2 top-2'
          />
          <Popup type='wfs' />
          <MyLayers>
            {/* https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoicGFhdXNwIiwiYSI6ImNsMmxleWFmeTBtYjIza25xOTJoN3U4ODkifQ.PVaXfWzvyFDLEaWlRQrd3w */}

            <TileLayer
              source={xyz('http://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}')}
              zIndex={0}
            />
            {geoj && (
              <VectorLayer
                source={vector({
                  features: new GeoJSON().readFeatures(geoj, {
                    featureProjection: get('EPSG:4326'),
                  }),
                })}
                // style={FeatureStyles.MultiPolygon}
              />
            )}
          </MyLayers>
        </MyMap>
      </div>
    </div>
  );
}

export default Dashboard;
